.logo {
    display: grid;
    width: 100%;
    place-items: center;
}

.logo img {
    display: block;
    margin: 0 auto;
    width: 150px;
    height: 150px;
    -webkit-animation: fadeinout 2s linear forwards;
    animation: fadeinout 2s linear forwards;
    animation-iteration-count: infinite;
}

@-webkit-keyframes fadeinout {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes fadeinout {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}